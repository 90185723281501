
new fullpage('#fullpage', {
        navigation: true,
        css3: true,
        anchors: ['page1', 'page2', 'page3', 'page4', 'page5', 'page6', 'page7'],
        navigationTooltips: ['Главная', 'О скрипте', 'Цены',  'Видео', 'Отзывы', ' F.A.Q.', 'Контакты'],
        scrollOverflow: true,
        // paddingTop: 100, 
        // paddingBottom: 100,
}); 
